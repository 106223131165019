















import {Component, Ref, Vue} from 'vue-property-decorator';
import VueContext from 'vue-context';
import {ReferralCommission} from '@/plugins/api';
import ContextFavorecido from '@/components/ContextOptions/ContextFavorecido.vue';
import ContextIndicador from '@/components/ContextOptions/ContextIndicador.vue';
import {situacoes} from './tabela';

@Component({
  components: {
    ContextFavorecido,
    ContextIndicador,
  },
})
export default class ReferralCommissionContext extends Vue {
  @Ref() public readonly menu!: VueContext;
  public situacoes = situacoes;

  public open(event: MouseEvent, item: IReferralCommission): void {
    this.menu.open(event, item);
  }

  public get route(): string {
    return this.$route.name as string;
  }

  public refresh(): void {
    this.close();
    this.$emit('refresh');
  }

  public close(): void {
    this.menu.close();
    this.$emit('close');
  }

  public mudarSituacao(id: number, status: string): void {
    this.close();

    const data: PatchReferralCommissionRequest = {
      status: status,
    };
    ReferralCommission.patch(id, data)
        .then(() => {
          this.$emit('refresh');
        })
        .catch((err) => {
          this.$swal({
            icon: 'error',
            title: err.response.data,
          });
        });
  }

  public recalcularReferralCommission(id: number): void {

    ReferralCommission.recalcular(id)
        .then((data) => {
          this.$emit('refresh');
          console.log(data);
        })
        .catch((err) => {
          this.$swal({
            icon: 'error',
            title: err.response.data,
          });
        });
  }

}
