








import { Component, Vue } from 'vue-property-decorator';
import { columnsFilter } from './components/tabela';

@Component
export default class SaquesIndex extends Vue {
  created(): void {
    this.$store.commit('SET_TABELA', 'Saques');
    this.$store.commit('SET_COLUNAS', columnsFilter);
  }
}
