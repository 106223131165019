
































































































































































import {Component, Ref, Vue} from 'vue-property-decorator';
import {SweetAlertOptions} from 'sweetalert2';
import {Api, Auth, Saque} from '@/plugins/api';
import {FilePond} from 'filepond';
import ComissoesTable from '@/views/app/Comissoes/components/ComissoesTable.vue';
import CardIndicador from '@/views/app/Indicadores/components/CardIndicador.vue';
import ComprovantesGrid from '@/views/app/Indicadores/components/ComprovantesGrid.vue';
import ReferralCommissionTable from "@/views/app/ReferralCommission/components/ReferralCommissionTable.vue";
import ReferralCommission from "@/plugins/api/ReferralCommission";
import ExcluirComprovante from "@/views/app/Indicadores/components/ExcluirComprovante.vue";

@Component({
  components: {
    ExcluirComprovante,
    ReferralCommissionTable,
    CardIndicador,
    ComprovantesGrid,
  },
})
export default class InfoSaque extends Vue {
  @Ref() readonly comissoesTable!: ComissoesTable;
  @Ref() readonly filepond!: FilePond;
  public selectedTab = 0;
  public progressSituacao = false;

  public loadingSaque = true;
  public saqueId!: number;
  public indicadorId = 0;
  public saque = {} as ISaque;

  public showPopover = false;
  public comprovanteIndex = null;
  public filePondServer = {};
  public baseFiles!: string|undefined;

  created(): void {

    this.loadingSaque = true;
    this.saqueId = parseInt(this.$route.params.id, 10);
    this.updateInfo();
    this.baseFiles = Api.baseFilesUrl;
    this.filePondServer = {
      url: Api.baseUrl,
      process: {
        url: `/saques/${this.saqueId}/comprovantes-referral`,
        headers: {
          Authorization: Auth.instance.token,
        },
      },
    };
  }

  updateInfo(): void {
    this.loadingSaque = true;

    Saque.getByID(this.saqueId)
        .then((response: GetSaqueResponse) => {
          this.loadingSaque = false;
          this.saque = response.data;
          this.indicadorId = this.saque.id_usuario;
        })
        .catch((err) => {
          this.$router.replace('/saques');
          this.$swal({
            icon: 'error',
            title: err.response.data,
          });
        });
  }

  marcarPago(): void {

    if (!this.saque.comprovante) {
      this.showPopover = !this.showPopover;
    } else {
      this.showPopover = false;
      this.marcarSituacao('pago');
    }
  }

  marcarSituacao(situacao: string): void {
    this.progressSituacao = true;
    const params: PatchReferralCommissionRequest = {
      status: situacao,
    };
    ReferralCommission.patch(this.saqueId, params)
        .then((response) => {
          this.comissoesTable.refresh();
          this.updateInfo();
          this.toastSuccessError(response.success);
        })
        .catch(() => {
          this.toastSuccessError(false);
        })
        .finally(() => {
          this.progressSituacao = false;
        });
  }

  toastSuccessError(success: boolean, email?: boolean): void {
    let options = {} as SweetAlertOptions;
    if (success) {
      const text = email ?? false ? 'Email enviado para indicador' : undefined;
      options = {
        icon: 'success',
        title: 'Salvo com sucesso!',
        text: text,
      };
    } else {
      options = {
        icon: 'error',
        title: 'Falha ao mudar situação!',
      };
    }
    this.$swal(options);
  }

  uploadedFiles(): void {
    this.updateInfo();
  }
}
